import React from 'react';

// PreviewArea.js 内にスタイルを定義する場合
// const imageStyle = {
//     maxWidth: '100%',
//     height: 'auto',
//     display: 'block',
//     margin: '0 auto' // 画像を中央に配置
//   };
  
  function PreviewArea({ htmlContent }) {
    const styledHtmlContent = `
      <style>
        img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
        video {
          max-width: 90%;
          haight: auto;
          display: block;
          margin: 0 auto;
        }

        </style>
      ${htmlContent}
    `;
  
    return (
      <div dangerouslySetInnerHTML={{ __html: styledHtmlContent }} />
    );
  }
  

export default PreviewArea;
