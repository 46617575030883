import React, { useState } from 'react';
import Modal from './Modal'; // Modal コンポーネントをインポート

const Downloader = ({ data, showTableOfContents }) => {
  const [fileType, setFileType] = useState('pdf');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // 目次のHTMLを生成する関数
  const generateTableOfContentsHtml = (chapters) => {
    let tocHtml = '<div class="table-of-contents"><ul class="toc-chapters">';
    chapters.forEach((chapter, chapterIndex) => {
      // 各章に一意のIDを割り当てる
      const chapterId = `chapter-${chapterIndex}`;
      tocHtml += `<li class="toc-chapter"><a href="#${chapterId}">${chapter.title}</a></li>`;

      if (chapter.steps && chapter.steps.length > 0) {
        tocHtml += '<ul class="toc-steps">';
        chapter.steps.forEach((step, stepIndex) => {
          const stepPrefix = chapter.selectedPrefix === 'CUSTOM' ? chapter.customTitle : chapter.selectedPrefix;
          tocHtml += `<li class="toc-step">${stepPrefix}${stepIndex + 1}: ${step.title}</li>`;
        });
        tocHtml += '</ul>';
      }
    });
    tocHtml += '</ul></div>';
    return tocHtml;
  };

    // 各章のHTMLコンテンツを生成する関数
    const generateChaptersHtml = (chapters) => {
      return chapters.map((chapter, chapterIndex) => {
        const chapterId = `chapter-${chapterIndex}`;
        let chapterHtml = `<div id="${chapterId}" class="chapter-content"><h2>${chapter.title}</h2>\n`;
        chapterHtml += chapter.content;
        if (chapter.steps) {
          chapterHtml += chapter.steps.map((step, index) => {
            const stepPrefix = chapter.selectedPrefix === 'CUSTOM' ? chapter.customTitle : chapter.selectedPrefix;
            return `<h3>${stepPrefix}${index + 1}: ${step.title}</h3>\n<p>${step.content}</p>`;
          }).join('\n');
        }
        return chapterHtml;
      }).join('\n');
    };

    // スタイル定義
    const styles = {
      container: {
        border: '1px solid #ccc',
        backgroundColor: '#f5f5f5',
        padding: '20px',
        marginTop: '0',
        borderRadius: '4px',
        width: '90%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      button: {
        padding: '10px 15px',
        margin: "4px 2px",
        backgroundColor: '#007bff',
        color: 'white',
        fontSize: "18px",
        border: 'none',
        borderRadius: '12px',
        cursor: 'pointer',
        transition: "background-color 0.3s",
        width: "200px",
        height: "40px",
      },
      button2: {
        padding: '10px 15px',
        margin: "4px 2px",
        backgroundColor: '#4CAF50',
        color: 'white',
        fontSize: "18px",
        border: 'none',
        borderRadius: '12px',
        cursor: 'pointer',
        transition: "background-color 0.3s",
        width: "200px",
        height: "40px",
      },
      select: {
        height: '40px',
        width: '200px',
        fontSize: "18px",
        padding: '10px 15px',
        margin: '5px',
        border: '1px solid #ddd',
        borderRadius: '3px',
        boxSizing: 'border-box'
      },
      listItemStyle: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'blue',
        marginBottom: '5px',
      },
      listItemHoverStyle: {
        textDecoration: 'underline',
        color: 'red',
      },
      tableOfContentsStyle: {
        backgroundColor: '#f5f5f5',
        border: '2px solid lightgrey',
        padding: '10px',
        margin: 'auto', // 中央寄せ
        borderRadius: '10px',
        width: '90%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
      },
      stepListItemStyle: {
        marginLeft: '20px',
        textDecoration: 'underline',
        color: 'blue',
        marginBottom: '5px',
        textAlign: 'left',
      },
      imageStyle: {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
      },
      videoStyle: {
        maxWidth: '90%',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
      },
      chapterContainer: {
        Width: '90%',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
      }
    };

      // CSSスタイルを文字列に変換する関数
      const convertStyleToCss = (styleObject) => {
        return Object.entries(styleObject).map(([key, value]) => {
          const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
          return `${cssKey}: ${value};`;
        }).join(' ');
      };

    // imageStyleCss, tableOfContentsCss, chapterContainerCssを定義する
    const imageStyleCss = `img { ${convertStyleToCss(styles.imageStyle)} }`;
    const videoStyleCss = `video { ${convertStyleToCss(styles.videoStyle)} }`;
    const tableOfContentsCss = `.table-of-contents { ${convertStyleToCss(styles.tableOfContentsStyle)} }`;
    const chapterContainerCss = `.chapter-content { ${convertStyleToCss(styles.chapterContainer)} }`;

    // finalHtmlContentを生成する関数
    const generateFinalHtmlContent = () => {
      let tableOfContentsHtml = showTableOfContents ? generateTableOfContentsHtml(data) : '';
      let chaptersHtml = generateChaptersHtml(data);
  
      return `<style>
        ${imageStyleCss}      
        ${videoStyleCss}
        ${tableOfContentsCss}
        ${chapterContainerCss}
        h2, h3 { /* h2, h3タグのスタイル */ }
        ul, li { /* 目次のスタイル */ }
        img { max-width: 100%; height: auto; }
        p { /* pタグのスタイル */ }
        </style>
        <div class='table-of-contents-container'>${tableOfContentsHtml}</div>
        <div class='chapters-container'>${chaptersHtml}</div>`;
      };

  // HTMLをマークダウンに変換する関数
  const convertHtmlToMarkdown = (chapters) => {
    let markdown = '';
    chapters.forEach((chapter) => {
      markdown += `## ${chapter.title}\n`;
      markdown += `${chapter.content}\n`;
      if (chapter.steps) {
        chapter.steps.forEach((step, stepIndex) => {
          // const stepPrefix = chapter.selectedPrefix === 'CUSTOM' ? chapter.customTitle : `STEP ${stepIndex + 1}`;
          markdown += `### ${chapter.selectedPrefix}${stepIndex + 1}: ${step.title}\n${step.content}\n`;
        });
      }
      markdown = markdown.replace(/<h([1-3])>(.*?)<\/h\1>/g, (_, level, content) => `${'#'.repeat(level)} ${content}`);
      markdown = markdown.replace(/<(strong|b)>(.*?)<\/\1>/g, '**$2**');
      markdown = markdown.replace(/<(em|i)>(.*?)<\/\1>/g, '_$2_');
      markdown = markdown.replace(/<ul>(.*?)<\/ul>/gs, (_, content) => content.replace(/<li>(.*?)<\/li>/g, '* $1\n'));
      markdown = markdown.replace(/<ol>(.*?)<\/ol>/gs, (_, content) => {
        let counter = 1;
        return content.replace(/<li>(.*?)<\/li>/g, () => `${counter++}. $1\n`);
      });
      markdown = markdown.replace(/<p>(.*?)<\/p>/g, '$1\n\n');
      markdown = markdown.replace(/<br\s*\/?>/g, '\n');
      markdown = markdown.replace(/<[^>]*>?/gm, '');
      markdown += '\n';
    });
    return markdown;
  };

  // HTMLプレビュー機能（別タブ）
  const previewAsHTMLTab = () => {
    const finalHtmlContent = generateFinalHtmlContent();
    const previewWindow = window.open('', '_blank');
    previewWindow.document.open();
    previewWindow.document.write(`<html><body>${finalHtmlContent}</body></html>`);
    previewWindow.document.close();
  };

  // ファイルタイプに基づいてコンテンツを生成する関数
  const generateContent = () => {
    switch (fileType) {
      case 'html':
        return showTableOfContents ? generateTableOfContentsHtml(data) + generateChaptersHtml(data) : generateChaptersHtml(data);
      case 'markdown':
        return convertHtmlToMarkdown(data);
        case 'text':
          let textContent = data.map(chapter => {
            let chapterText = `CHAPTER ${chapter.index + 1}: ${chapter.title}\n\n`;
            chapterText += removeHtmlTags(chapter.content).replace(/<br\s*\/?>/g, '\n');
            if (chapter.steps) {
              chapter.steps.forEach((step, stepIndex) => {
                const stepTitle = chapter.selectedPrefix === 'CUSTOM' ? chapter.customTitle : `STEP ${stepIndex + 1}`;
                chapterText += `\n${stepTitle}${stepIndex + 1}: ${step.title}\n`;
                chapterText += removeHtmlTags(step.content).replace(/<br\s*\/?>/g, '\n');
              });
            }
            return chapterText;
          }).join('\n\n');
          return textContent;
        default:
          return '';
      }
    };

    // HTMLタグを除去する関数
    const removeHtmlTags = (text) => {
      return text.replace(/<[^>]*>?/gm, '');
    };

  // テキスト、HTML、マークダウンとしてダウンロードする関数
  const downloadContent = (content, fileType) => {
    const blob = new Blob([content], { type: `text/${fileType};charset=utf-8;` });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `output.${fileType}`;
    a.click();
    URL.revokeObjectURL(url);
  };

    // プレビュー機能
    const handlePreview = () => {
      const content = generateContent();
      setModalContent(content);
      setShowModal(true);
    };
  
  // プレビューとダウンロードの統合ハンドラ
  const handleAction = (action) => {
    let content = generateContent();
    if (fileType === 'text') {
      content = content.replace(/\n/g, '<br>');
    }
    if (action === 'preview') {
      if (fileType === 'html') {
        previewAsHTMLTab();
      } else {
        setModalContent(content);
        setShowModal(true);
      }
    } else {
      downloadContent(content, fileType);
    }
  }

  // コンポーネントのレンダリング
  return (
    <div style={styles.container}>

    <div style={{ marginBottom: '20px' }}>
    <button style={styles.button2} onClick={previewAsHTMLTab}>プレビュー</button>
    </div>


      <select style={styles.select} onChange={(e) => setFileType(e.target.value)}>
        {/* <option value="pdf">PDF</option> */}
        <option value="text">テキスト</option>
        <option value="html">HTML</option>
        <option value="markdown">Markdown</option>
      </select>
      <button style={styles.button} onClick={handlePreview}>コードコピー</button>
      <button style={styles.button} onClick={handleAction}>ダウンロード</button>

      {showModal && <Modal content={modalContent} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Downloader;
