import React, { useState, useCallback, useRef, useEffect } from 'react';
import Quill from 'quill'; // Quill.jsをインポート
import QuillResize from 'quill-resize-module';
import 'quill/dist/quill.snow.css';
import Downloader from './components/Downloader';
import TableOfContents from './components/TableOfContents';
import PreviewArea from './components/PreviewArea';

// QuillResizeモジュールを登録
Quill.register('modules/resize', QuillResize);

// カスタムツールバーの定義
const CustomToolbar = ({ onUndo, onRedo }) => (
  <div id="toolbar" style={{ marginBottom: '5px' }}>
    <button className="ql-undo" onClick={onUndo} style={buttonStyle}>
      <span className="icon-undo">◁</span> {/* 戻るボタンのアイコン */}
    </button>

    <button className="ql-redo" onClick={onRedo} style={buttonStyle}>
      <span className="icon-redo">▷</span> {/* やり直しボタンのアイコン */}
    </button>
  </div>
);

// ボタンスタイル
const buttonStyle = {
  borderRadius: '5px',
  marginLeft: '20px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
};

// MaterialEditor コンポーネント
function MaterialEditor({ chapterId, initialContent, onContentChange, resetContent, onResetComplete }) {
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  
  // エディタの初期化とイベントハンドラの設定
  useEffect(() => {
    const editorId = `editor-${chapterId}`;
    if (!editorRef.current) {
      const container = document.getElementById(editorId);
      if (container) {
        const quill = new Quill(container, {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'color': [] }, { 'background': [] }],
              ['link', 'image', 'video'],
              ['code-block']
            ],
          
          history: { // 履歴モジュールを有効化
            delay: 500,
            maxStack: 100,
            userOnly: false
          },

          },
          theme: 'snow',
        });

      // 初期化時にinitialContentがある場合、それをエディタにロード
      if (initialContent) {
        quill.clipboard.dangerouslyPasteHTML(0, initialContent);
      }

        // カスタムサイズの登録
        const Size = Quill.import('attributors/style/size');
        Size.whitelist = ['small', 'medium', 'large', 'original'];
        Quill.register(Size, true);

        // 画像挿入処理のハンドラ
        const imageHandler = () => {
          fileInputRef.current.accept = 'image/*';
          fileInputRef.current.click();
          fileInputRef.current.onchange = () => {
            const file = fileInputRef.current.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const url = e.target.result;
                const size = prompt("Choose image size: original, large, medium, small", "original");
                let imgStyle = size === 'large' ? "width: 100%;" :
                               size === 'medium' ? "width: 50%;" :
                               size === 'small' ? "width: 25%;" : "";
                const imgHtml = `<img src="${url}" style="${imgStyle}" />`;
                const range = quill.getSelection(true);
                quill.clipboard.dangerouslyPasteHTML(range.index, imgHtml);
              };
              reader.readAsDataURL(file);
            }
          };
        };

          // 動画挿入処理のハンドラ
          const videoHandler = () => {
            fileInputRef.current.accept = 'video/*';
            fileInputRef.current.click();
            fileInputRef.current.onchange = () => {
              const file = fileInputRef.current.files[0];
              if (file) {
                console.log("選択されたファイル:", file.name, "サイズ:", file.size, "タイプ:", file.type);

                if (file.size > 10000000) { // 例えば10MBを超えるファイルを拒否
                  alert('ファイルが大きすぎます。');
                  return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                  console.log("ファイル読み込み完了:", file.name);
                  const videoUrl = e.target.result;
                  insertVideoToEditor(videoUrl);
                };
                reader.onerror = (e) => {
                  console.error("ファイル読み込みエラー:", e);
                };
                reader.readAsDataURL(file);
              } else {
                console.log("ファイルが選択されていません");
              }
            };
          };


          // Quillエディタに動画を挿入する関数
          const insertVideoToEditor = (videoUrl) => {
            const range = editorRef.current.getSelection(true);
            if (range) {
              editorRef.current.insertEmbed(range.index, 'video', videoUrl);
            } else {
              console.log("選択範囲がありません");
            }
          
            setTimeout(() => {
              console.log("挿入後のエディタのHTML:", editorRef.current.root.innerHTML);
              const videoElements = editorRef.current.root.getElementsByTagName('video');
              if (videoElements.length > 0) {
                const videoElement = videoElements[videoElements.length - 1];
                console.log("挿入された動画のHTML:", videoElement.outerHTML);
                console.log("動画のスタイル:", window.getComputedStyle(videoElement));
              } else {
                console.log("動画が挿入されていません");
              }
            }, 0);
          };

          const VideoBlot = Quill.import('formats/video');
          class CustomVideoBlot extends VideoBlot {
            static create(value) {
              let node = document.createElement('video');
              node.setAttribute('src', value);
              node.setAttribute('controls', true);
              return node;
            }
          }
          Quill.register(CustomVideoBlot, true);
          

                quill.getModule('toolbar').addHandler('image', imageHandler);
                quill.getModule('toolbar').addHandler('video', videoHandler);

          quill.on('text-change', () => {
            const currentContent = quill.root.innerHTML;
            localStorage.setItem(`chapter-${chapterId}`, currentContent); // 現在の内容をローカルストレージに保存
            onContentChange(currentContent);
          });

                editorRef.current = quill;
              }
            }

          }, [chapterId, initialContent, onContentChange]);

          // 戻るボタンのハンドラ
          const handleUndo = () => {
            if (editorRef.current) {
              editorRef.current.history.undo();
            }
          };

          // やり直しボタンのハンドラ
          const handleRedo = () => {
            if (editorRef.current) {
              editorRef.current.history.redo();
            }
          };


  // ローカルストレージからの読み込み
  useEffect(() => {
    if (editorRef.current && !initialContent) {
    const storedContent = localStorage.getItem(`chapter-${chapterId}`);
    if (storedContent && editorRef.current) {
      editorRef.current.root.innerHTML = storedContent; // ストレージの内容をエディタに反映
    }
  }

  }, [chapterId, initialContent]);

  useEffect(() => {
    if (resetContent && editorRef.current) {
      editorRef.current.setContents([]); // エディタの内容を空の Delta オブジェクトで置き換え
      onResetComplete(); // リセットが完了したことを親コンポーネントに通知
    }
  }, [resetContent, onResetComplete]);



        // 新たに追加する保存処理
        useEffect(() => {
          if (editorRef.current) {
            // エディタの内容が変更された際のイベントハンドラ
            const handleContentChange = () => {
              const newContent = editorRef.current.root.innerHTML;
              localStorage.setItem(`chapter-${chapterId}`, newContent);
              onContentChange(newContent);
            };
      
            editorRef.current.on('text-change', handleContentChange);
            return () => editorRef.current.off('text-change', handleContentChange);
          }
        }, [chapterId, onContentChange]);

    // エディタが初期化された後
    if (editorRef.current) {
      editorRef.current.on('text-change', () => {
        const newContent = editorRef.current.root.innerHTML;
        onContentChange(newContent);
      });
    }

  return (
    <div>
      <CustomToolbar onUndo={handleUndo} onRedo={handleRedo} />

      <div id={`editor-${chapterId}`}></div>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
    </div>
  );
}


function App() {
  const [blueprintText, setBlueprintText] = useState('');
  const [tocText, setTocText] = useState('');
  const [chapters, setChapters] = useState([]);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [loadedFileName, setLoadedFileName] = useState(''); // ファイル名を保持するための状態変数

  const fileInputRef = useRef(null); // ここでfileInputRefを作成


  // テーブルオブコンテンツの表示切り替え
  const handleTableOfContentsClick = () => {
    setShowTableOfContents(!showTableOfContents);
  };

  // ブループリントと目次のテキスト変更ハンドラ
  const handleBlueprintChange = (e) => {
    setBlueprintText(e.target.value);
  };

  const handleTocChange = (e) => {
    setTocText(e.target.value);
  };

    // 目次から章を生成する関数
    const generateChaptersFromToc = () => {
      const chapterTitles = tocText.split('\n').filter(line => line.trim() !== '');
      const blueprintLines = blueprintText.split('\n');
      const newChapters = chapterTitles.map((title, index) => ({
        // ステップのタイトルとコンテンツを初期化
        title, 
        content: '',
        steps: [], // 空の配列で初期化
        selectedPrefix: 'STEP', // 初期値をSTEPに設定
        blueprint: blueprintLines[index] || '' // 対応する設計図の行
      }));
      setChapters(newChapters);
    };

  // 章とステップの参照を管理
  const chapterRefs = useRef([]);
  const stepRefs = useRef([]);

  useEffect(() => {
    chapterRefs.current = chapters.map((_, i) => chapterRefs.current[i] || React.createRef());
    stepRefs.current = chapters.map(chapter => chapter.steps.map(() => React.createRef()));
  }, [chapters]);

  // 章の変更ハンドラ
  const handleChapterChange = useCallback((index, field, value) => {
    const updatedChapters = chapters.map((chapter, i) => {
      if (i === index) {
        return { ...chapter, [field]: value, steps: chapter.steps };
      }
      return chapter;
    });
    setChapters(updatedChapters);
  }, [chapters]);

  // ステップの内容変更ハンドラ
  const handleStepChange = (chapterIndex, stepIndex, newContent) => {
    setChapters(chapters.map((chapter, i) => {
      if (i === chapterIndex) {
        const updatedSteps = chapter.steps.map((step, j) => {
          if (j === stepIndex) {
            return { ...step, content: newContent };
          }
          return step;
        });
        return { ...chapter, steps: updatedSteps };
      }
      return chapter;
    }));
  };

  // ステップタイトル入力ハンドラ
  const handleStepTitleInput = (chapterIndex, value) => {
    const updatedChapters = chapters.map((chapter, index) => {
      if (index === chapterIndex) {
        return { ...chapter, stepTitleInput: value };
      }
      return chapter;
    });
    setChapters(updatedChapters);
  };

  // 章にステップを追加
  const addStepToChapter = (chapterIndex) => {
    setChapters(prevChapters => {
      return prevChapters.map((chapter, index) => {
        if (index === chapterIndex) {
          const stepTitles = chapter.stepTitleInput ? chapter.stepTitleInput.split('\n').filter(title => title.trim() !== '') : [`STEP ${chapter.steps.length + 1}`];
          const newSteps = stepTitles.map(title => ({ title, content: '' }));
          return { 
            ...chapter, 
            steps: [...(chapter.steps || []), ...newSteps],
            stepTitleInput: '' 
          };
        }
        return chapter;
      });
    });
  };

  // カスタムタイトルの処理
  const handleCustomTitle = (chapterIndex) => {
    const customTitle = prompt("カスタムタイトルを入力してください:");
    if (customTitle) {
      setChapters(chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            customTitle,
            selectedPrefix: 'CUSTOM'
          };
        }
        return chapter;
      }));
    }
  };

  // 全文リセット
  const handleFullReset = () => {
    setChapters([]);
    setShowTableOfContents(false); // 目次の表示をオフに設定
    // localStorage.clear(); // ローカルストレージの内容をクリア
  };
  

  // 章のリセット
  const handleChapterReset = chapterIndex => {
    setChapters(chapters.map((chapter, index) => {
      if (index === chapterIndex) {
        // localStorage.removeItem(`chapter-${chapterIndex}`);
        return { ...chapter, content: '', steps: [] };
      }
      return chapter;
    }));
    setResetFlag(true); // リセットフラグをセット
  };

  const resetComplete = () => {
    setResetFlag(false); // リセットが完了したのでフラグをリセット
  };
  

  // STEP削除
  const deleteStep = (chapterIndex, stepIndex) => {
    setChapters(prevChapters => {
      return prevChapters.map((chapter, i) => {
        if (i === chapterIndex) {
          const updatedSteps = chapter.steps.filter((_, j) => j !== stepIndex);
          return { ...chapter, steps: updatedSteps };
        }
        return chapter;
      });
    });
  };

  // ステップタイトル変更
  const handleStepTitleChange = (chapterIndex, stepIndex, newTitle) => {
    setChapters(chapters.map((chapter, i) => {
      if (i === chapterIndex) {
        const updatedSteps = chapter.steps.map((step, j) => {
          if (j === stepIndex) {
            return { ...step, title: newTitle };
          }
          return step;
        });
        return { ...chapter, steps: updatedSteps };
      }
      return chapter;
    }));
  };

  // プレビューエリアの画像スタイル調整
  const adjustPreviewContentStyle = () => {
    const images = document.querySelectorAll('.preview-area img');
    images.forEach(img => {
      img.style.maxWidth = '100%';
      img.style.height = 'auto';
    });
  };

  useEffect(() => {
    adjustPreviewContentStyle();
  }, [chapters]);

  // コンテンツ保存機能
  const saveContent = () => {
    const contentToSave = {
      blueprintText,
      tocText,
      chapters
    };
    localStorage.setItem('projectContent', JSON.stringify(contentToSave));
    alert('コンテンツが保存されました！');
  };


  // コンテンツ読み込み機能
  const loadContent = () => {
    const storedContent = localStorage.getItem('projectContent');
    if (storedContent) {
      const { blueprintText: loadedBlueprintText, tocText: loadedTocText, chapters: loadedChapters } = JSON.parse(storedContent);
      setBlueprintText(loadedBlueprintText);
      setTocText(loadedTocText);
      setChapters(loadedChapters);
      alert('コンテンツが読み込まれました！');
    }
  };

  const downloadChaptersAsJson = () => {
    const dataToDownload = {
      blueprintText,
      tocText,
      chapters
    };
    const dataStr = JSON.stringify(dataToDownload);
    const blob = new Blob([dataStr], { type: "application/json;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.href = url;
    link.download = "chapters.json";
    link.click();
  
    URL.revokeObjectURL(url);
  };

    // ファイル読み込みハンドラ
    const handleFileLoad = (event) => {
      const file = event.target.files[0];
      if (file) {
        setLoadedFileName(file.name); // ファイル名を更新
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          try {
            const json = JSON.parse(content);
            setBlueprintText(json.blueprintText);
            setTocText(json.tocText);
            setChapters(json.chapters); // ここを修正
            alert('ファイルが読み込まれました！');
          } catch (error) {
            alert('ファイルの読み込みに失敗しました。');
          }
        };
        reader.readAsText(file);
      }
    };
    

  const buttonStyle = {
    backgroundColor: "#00AEE0", border: "none", color: "white",
    fontSize: "18px", margin: "4px 2px", cursor: "pointer", borderRadius: "12px",
    transition: "background-color 0.3s", width: "200px", height: "40px"
  };

  const buttonStyle1 = {
    backgroundColor: "#4CAF50", border: "none", color: "white",
    // padding: "12px 24px", textAlign: "center", display: "inline-block",
    fontSize: "18px", margin: "4px 2px", cursor: "pointer", borderRadius: "12px",
    transition: "background-color 0.3s", width: "200px", height: "40px"
  };

  const buttonStyle2 = {
    backgroundColor: "black", border: "none", color: "white",
    // padding: "12px 24px", textAlign: "center", display: "inline-block",
    fontSize: "18px", margin: "4px 2px", cursor: "pointer", borderRadius: "12px",
    transition: "background-color 0.3s", width: "200px", height: "40px"
  };

  const frameStyle = {
    border: '1px solid #ccc',
    backgroundColor: '#f5f5f5',
    padding: '20px',
    marginTop: '0',
    borderRadius: '4px',
    width: '90%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto'
  };

  return (
    <div>
      <h1 style={{ backgroundColor: 'lightgreen', padding: '10px' }}>KYOUZAI TUKOOL</h1>
      <div style={{ display: 'flex', gap: '20px', marginTop: '10px', padding: '0 20px' }}>
        <div style={{ flex: 1 }}>
          {/* 設計図エリア */}
          <div style={{ marginBottom: '20px' }}>
            <textarea
              style={{ height: '100px', width: '100%', resize: 'vertical', fontSize: "14px" }}
              placeholder="設計図をここにペースト"
              value={blueprintText}
              onChange={handleBlueprintChange}
            />
          </div>
  
          {/* 章タイトルエリア */}
          <div style={{ marginBottom: '20px' }}>
            <textarea
              style={{ height: '100px', width: '100%', resize: 'vertical', fontSize: "14px" }}
              placeholder="章のタイトルをここにペースト"
              value={tocText}
              onChange={handleTocChange}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              {/* INDEXを作成ボタン */}
              <div style={{ flex: 1, textAlign: 'center' }}>
                <button style={buttonStyle2} onClick={generateChaptersFromToc}>
                  INDEXを作成
                </button>
              </div>
  
              {/* 目次を表示ボタン */}
              <div style={{ flex: 1, textAlign: 'center' }}>
                <button style={buttonStyle} onClick={handleTableOfContentsClick}>
                  目次を表示
                </button>
              </div>
  
              {/* 全文リセットボタン */}
              <div style={{ flex: 1, textAlign: 'center' }}>
                <button style={buttonStyle1} onClick={handleFullReset}>
                  全文リセット
                </button>
              </div>
            </div>
          </div>
  
          {/* 章の編集エリア */}
          {chapters.map((chapter, index) => (
            <div key={index} style={{ marginBottom: '20px', backgroundColor: '#f5f5f5', boxShadow: '0 2px 4px rgba(0,0,0,0.2)', borderRadius: '10px', padding: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <p style={{ color: 'grey' }}>{`CHAPTER ${index + 1}: ${chapter.title}`}</p>
                <button style={buttonStyle1} onClick={() => handleChapterReset(index)}>CHAPTERリセット</button>
              </div>
  
              {/* CHAPTER編集ボックス */}
              <p style={{ color: 'grey' }}>{chapter.blueprint}</p>
              <input
                type="text"
                value={chapter.title}
                onChange={e => handleChapterChange(index, 'title', e.target.value)}
                style={{ width: '100%', height: '30px', marginBottom: '10px' }}
              />
  
              {/* 章メインテキスト */}
              <p style={{ color: 'grey' }}>CHAPTERメインテキスト:</p>
              <MaterialEditor 
                chapterId={index}
                initialContent={chapter.content}
                onContentChange={(newContent) => handleChapterChange(index, 'content', newContent)}
                resetContent={resetFlag}
                onResetComplete={resetComplete}
                style={{ marginBottom: '15px' }}
              />
  
              {/* STEPタイトル入力エリア */}
              <div style={{ marginTop: '20px' }}>
                <p style={{ color: 'grey' }}>STEP作成エリア:</p>
                <div style={{ marginBottom: '20px' }}>
                  <select
                    value={chapter.selectedPrefix}
                    onChange={e => {
                      if (e.target.value === "CUSTOM") {
                        handleCustomTitle(index);
                      } else {
                        setChapters(chapters.map((ch, idx) => {
                          if (idx === index) {
                            return { ...ch, selectedPrefix: e.target.value };
                          }
                          return ch;
                        }));
                      }
                    }}
                    style={{ 
                      height: '40px',
                      width: '200px',
                      fontSize: "18px",
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '5px',
                      boxSizing: 'border-box',
                      marginRight: '10px'
                    }}
                  >
                    <option value="STEP">STEP</option>
                    <option value="CASE">CASE</option>
                    <option value="CUSTOM">CUSTOM</option>
                  </select>
                </div>
  
                <textarea
                  placeholder="タイトルを入力（改行で複数入力可）"
                  value={chapter.stepTitleInput}
                  onChange={e => handleStepTitleInput(index, e.target.value)}
                  style={{ height: '100px', width: '100%', resize: 'vertical', fontSize: "14px" }}
                />
                <div style={{ textAlign: 'center' }}>
                  <button style={buttonStyle2} onClick={() => addStepToChapter(index)}>
                    {chapter.steps.length > 0 ? '追加STEP作成' : 'STEP作成'}
                  </button>
                </div>
              </div>
  
              {chapter.steps.map((step, stepIndex) => (
                <div key={stepIndex}>
                  {/* ブループリント表示 */}
                  <div style={{ marginBottom: '10px' }}>
                    <h3 style={{ color: 'grey' }}>
                      {chapter.selectedPrefix === 'CUSTOM' ? `${chapter.customTitle}${stepIndex + 1}` : `${chapter.selectedPrefix}${stepIndex + 1}`}: {step.title}
                    </h3>
                    <p style={{ color: 'grey' }}>{chapter.blueprint}</p>
                  </div>
  
                  {/* ステップ編集エリア */}
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <input
                      type="text"
                      value={step.title}
                      onChange={(e) => handleStepTitleChange(index, stepIndex, e.target.value)}
                      style={{ width: '60%', marginRight: '10px', height: '30px' }}
                    />
                    <button style={buttonStyle1} onClick={() => deleteStep(index, stepIndex)}>削除</button>
                  </div>
  
                  {/* エディター */}
                  <MaterialEditor
                    chapterId={`${index}-${stepIndex}`}
                    initialContent={step.content}
                    onContentChange={(newContent) => handleStepChange(index, stepIndex, newContent)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
  
        {/* プレビューエリア */}
        <div style={{ width: '80%', border: '1px solid black', padding: '10px', overflow: 'hidden', maxWidth: '100%' }}>
        {showTableOfContents && (
          <TableOfContents
            chapters={chapters}
          />
        )}

          <div className="preview-area" style={{ width: '90%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'left' }}>
            {chapters.map((chapter, chapterIndex) => (
              <div key={chapterIndex} ref={chapterRefs.current && chapterRefs.current[chapterIndex]}>
                <p style={{ color: 'grey', marginLeft: '20px' }}>{chapter.blueprint}</p>
                <h2 style={{ marginLeft: '20px' }}>{chapter.title}</h2>
                <PreviewArea htmlContent={chapter.content} />
                {chapter.steps.map((step, stepIndex) => (
                  <div key={stepIndex} ref={stepRefs.current[chapterIndex] && stepRefs.current[chapterIndex][stepIndex]}>
                    <h3 style={{ marginLeft: '20px' }}>
                      {chapter.selectedPrefix === 'CUSTOM' ? `${chapter.customTitle}${stepIndex + 1}: ${step.title}` : `${chapter.selectedPrefix}${stepIndex + 1}: ${step.title}`}
                    </h3>
                    <PreviewArea htmlContent={step.content} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>

            <div style={{ marginBottom: '20px' }}>
            <div style={frameStyle}>

            <button onClick={saveContent} style={buttonStyle}>
            一時保存
            </button>

            <button onClick={loadContent} style={buttonStyle}>
            一時保存読み込み
            </button>


            <button style={buttonStyle2} onClick={downloadChaptersAsJson}>
              ファイルを保存
            </button>

            <input
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileLoad}
              ref={fileInputRef}
            />
            <button　style={buttonStyle2} onClick={() => fileInputRef.current.click()}>
              ファイルを読み込む
            </button>
            {loadedFileName && <p>読み込まれたファイル: {loadedFileName}</p>}

             </div>
            </div>

            {/* <div style={{ marginBottom: '20px' }}>
            <div style={frameStyle}>
            <WordPressIntegration content={generateContentForWordPress()} />
            </div>
            </div> */}

        <Downloader data={chapters} showTableOfContents={showTableOfContents} />
        </div>
      </div>
    </div>
  );
  
}

export default App;