import React, { useState } from 'react';

function Modal({ content, onClose, contentType }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // 3秒後に元のテキストに戻る
  };

  const headerStyle = {
    backgroundColor: '#586171', 
    color: 'white',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px 8px 0 0', // ヘッダー部分の角丸
  };


  const buttonStyle = {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: '5px 10px',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: 'lightgrey'
    }
  };

  const closeButtonStyle = {
    ...buttonStyle,
    width: '30px',
    height: '30px',
    fontSize: '20px',
    textAlign: 'center'
  };

  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'black',
    padding: '20px',
    zIndex: '1000',
    width: '80%',
    maxHeight: '80vh',
    overflowY: 'auto',
    textAlign: 'left',
    border: '1px solid lightgrey',
    borderRadius: '8px',
  };

  const hrStyle = {
    border: 'none',
    height: '1px',
    backgroundColor: 'grey',
    margin: '0'
  };

  return (
    <div style={modalStyle}>
      <div style={headerStyle}>
        <span>{contentType}</span>
        <span style={buttonStyle} onClick={handleCopy} onMouseOver={e => e.target.style.backgroundColor = 'lightgrey'} onMouseOut={e => e.target.style.backgroundColor = 'transparent'}>
          {copied ? '✔コピーしました' : '📋 Copy code'}
        </span>
        <span style={closeButtonStyle} onClick={onClose} onMouseOver={e => e.target.style.backgroundColor = 'red'} onMouseOut={e => e.target.style.backgroundColor = 'transparent'}>
          &times;
        </span>
      </div>
      <hr style={hrStyle} />
      <pre style={{ color: 'white' }}>{content}</pre>
    </div>
  );
}

export default Modal;
