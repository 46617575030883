import React, { useRef, useEffect } from 'react';

function TableOfContents({ chapters }) {
  // 章とステップの参照を保持する
  const chapterRefs = useRef(chapters.map(() => React.createRef()));
  const stepRefs = useRef(chapters.map(chapter => chapter.steps.map(() => React.createRef())));

  useEffect(() => {
    // 章とステップの参照を更新
    chapterRefs.current = chapters.map((_, i) => chapterRefs.current[i] || React.createRef());
    stepRefs.current = chapters.map((chapter, i) => 
      chapter.steps.map((_, j) => stepRefs.current[i][j] || React.createRef())
    );
  }, [chapters]);

  // 章またはステップへスクロール
  const scrollToLocation = (chapterIndex, stepIndex = null) => {
    if (stepIndex !== null && stepRefs.current[chapterIndex][stepIndex].current) {
      // ステップの参照があれば、そこへスクロール
      stepRefs.current[chapterIndex][stepIndex].current.scrollIntoView({ behavior: 'smooth' });
    } else if (chapterRefs.current[chapterIndex].current) {
      // 章の参照があれば、そこへスクロール
      chapterRefs.current[chapterIndex].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // リスト項目スタイル
  const listItemStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    marginBottom: '5px' // テキスト間の間隔を広げる
  };

  // リスト項目のホバースタイル
  const listItemHoverStyle = {
    textDecoration: 'underline',
    color: 'red',
  };

  // 目次ブロックのスタイル
  const tableOfContentsStyle = {
    backgroundColor: '#f5f5f5', // 薄いライトグリーン
    border: '2px solid lightgrey', // ボックスの境界線
    padding: '10px', // 内側の余白
    margin: '10px 0', // 外側の余白
    borderRadius: '10px', // 角を丸める
    width: '90%', // 幅を狭くする
    maxWidth: '500px', // 最大幅を制限
    marginLeft: 'auto', // 中央揃え
    marginRight: 'auto'
  };

  // ステップリスト項目スタイル（インデントを適用）
  const stepListItemStyle = {
    marginLeft: '20px', // インデント
    ...listItemStyle, // 他のリスト項目スタイルを継承
  };

  return (
    <div style={tableOfContentsStyle}>
      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
        {chapters.map((chapter, index) => (
          <>
            <li
              key={index}
              style={listItemStyle}
              onMouseEnter={(e) => { e.target.style.color = listItemHoverStyle.color; }}
              onMouseLeave={(e) => { e.target.style.color = listItemStyle.color; }}
              onClick={() => scrollToLocation(index)}
            >
              {`${chapter.title}`}
            </li>
            {chapter.steps && chapter.steps.map((step, stepIndex) => (
              <li
                key={`${index}-${stepIndex}`}
                style={stepListItemStyle}
                onMouseEnter={(e) => { e.target.style.color = listItemHoverStyle.color; }}
                onMouseLeave={(e) => { e.target.style.color = stepListItemStyle.color; }}
                onClick={() => scrollToLocation(index, stepIndex)}
              >
              {/* カスタムタイトルが設定されている場合、それを表示する */}
              {chapter.selectedPrefix === 'CUSTOM' ? `${chapter.customTitle}${stepIndex + 1}: ${step.title}` : `${chapter.selectedPrefix}${stepIndex + 1}: ${step.title}`}
            </li>
            ))}
          </>
        ))}
      </ul>

      {chapters.map((chapter, chapterIndex) => (
        <div key={chapterIndex} ref={chapterRefs.current[chapterIndex]}>
          {/* <div dangerouslySetInnerHTML={{ __html: chapter.content }} /> */}
          {chapter.steps.map((step, stepIndex) => (
            <div key={stepIndex} ref={stepRefs.current[chapterIndex][stepIndex]}>
              {/* <div dangerouslySetInnerHTML={{ __html: step.content }} /> */}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default TableOfContents;
